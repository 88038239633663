/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Ld4cjEnAAAAAFi3LvdoSVkwSDwTKsVSWYxo1cRK">
            {element}
        </GoogleReCaptchaProvider>
    )
}